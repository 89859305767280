import React, { useState } from 'react';
import axios from 'axios';

function EmailForm() {
    const [recipientEmail, setRecipientEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disableButton) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://ga6c870uf5.execute-api.eu-west-2.amazonaws.com/send-email', {
                recipientEmail: recipientEmail,
            }, {
            headers: {
                'Access-Control-Allow-Origin': '*'
              }
            });
            console.log(response.data);
            setMessage(response.data.message);

            // Disable the button for 30 seconds after successful submission
            setDisableButton(true);
            setTimeout(() => {
                setDisableButton(false);
            }, 30000); // 30 seconds
        } catch (error) {
            console.error('Error sending email:', error);
            setMessage('Error sending email. Please try again later.');
        }
        setLoading(false);
    };

    const buttonTooltip = disableButton ? 'Please wait before sending another email' : '';

    return (
        <div>
            <div className='container'>
                <img src='/biLogo.png' alt='BI Logo'></img>
                <form onSubmit={handleSubmit}>
                <h1>Begin activating the People Promise today!</h1>
                <h2>Enter your email address below to receive our suite of helpful assets to get you started.</h2>
                    <label>
                        <input
                            type="email"
                            value={recipientEmail}
                            onChange={(e) => setRecipientEmail(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit" disabled={loading || disableButton} title={buttonTooltip}>
                        {loading ? 'Sending Assets...' : 'Request Assets'}
                    </button>
                <div className='message'>
                {message && <p>{message}</p>}
                </div>
                <img className='graphic' src='/footerImage.jpg' alt='BI Logo'></img>
                </form>
            </div>
        </div>
    );
}

export default EmailForm;
